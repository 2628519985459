import './sectionThree.css'
import Title from '../title/Title';
import ButtonContacto from '../buttonContacto/ButtonContacto';
import datosImg from '../../assets/img/datosImg.svg'

function SectionThree() {

    return (
        <section className='section-3'>
            <div className='main-container'>
                <Title 
                    titulo={["Los datos generados serán tu mejor ",<b>ALIADO</b>,<span className='dot'>.</span>]}
                    subtitulo={"Al integrar sus máquinas u operaciones con nuestros dispositivos, lograrás flujos de trabajo más eficientes y estrategias basadas en una mejor data, mejorando la productividad y reduciendo pérdidas."}
                    seccion={3}
                />
                <div className='button-container-3'>
                    <ButtonContacto />
                </div>
                <div className='img-container-3'>
                    <img src={datosImg} alt="circulo color negro" className='img-fluid' />
                </div>
            </div>
        </section>
    )
};

export default SectionThree;