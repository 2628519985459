import { Routes, Route } from "react-router-dom"
import Home from "../views/home/Home";
import FormContacto from "../components/formContacto/FormContacto";

function AppRoutes() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/form-contacto" element={ <FormContacto/> } />
        <Route path="/prueba" element={<p>Path not resolved</p>} />
      </Routes>
    </div>
  );
}

export default AppRoutes;