import './buttonContacto.css'
import iotArrow from '../../assets/img/iotArrow.svg'
import { Link } from 'react-router-dom';

function ButtonContactoMobile() {
    return(
        <div className='btn-container-mobile'>
            <div className='contacto-container-mobile'>
                <Link className='contacto-font-mobile mx-auto' to="/form-contacto">contacto</Link>
                <img src={iotArrow} alt="flecha contacto" className='img-contacto-mobile' />
            </div>
        </div>
    )
};

export default ButtonContactoMobile;