import './sectionOne.css'
import Title from '../../components/title/Title';
import iotArrow2 from '../../assets/img/iotArrow2.svg'
import iotService from '../../assets/img/iotService.png'

function SectionOne() {

    return (
        <section className='section section-1'>
        <Title 
          titulo={['Pasión por la innovación y el diseño de ', <b>soluciones</b>, <span className='dot'>.</span>]}
          subtitulo={'Nos hemos dedicamos por más de 10 años a diseñar y fabricar productos de calidad que superen las expectativas de nuestros clientes.'}
          seccion={1}
        />
        <div className='imgs-container'>
          <div className='iot-arrow-2'>
            <img src={iotArrow2} alt="flecha en direccion diagonal superior derecha" className='img-fluid'/>
          </div>
          <div className='iot-service'>
            <img src={iotService} alt="Servicios iot" className='img-fluid'/>
            <div className="vertical-line"></div>
          </div>
        </div>
      </section>
    )
};

export default SectionOne;