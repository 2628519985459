import './sectionTwo.css'
import elipse from '../../assets/img/elipse.svg'
import procesos from '../../assets/img/procesos.png'
import ButtonContacto from '../buttonContacto/ButtonContacto'

function SectionTwo() {

    return (

      <>
        <section className='section section-2'>
          <div className='line-container'>
            <div className="vertical-line-2"></div>
          </div>
          <div className='titulo-procesos-container'>
            <div className='elipse-container'>
              <div className='titulo-container'>
                <h1 className='titulo'>
                  Procesos
                </h1>
              </div>
              <div className='img-elipse-container'>
                <div className='line-container-2'>
                  <div className="vertical-line-3"></div>
                </div>
                <img src={elipse} alt="circulo color negro" className='img-fluid elipse' />
              </div>
            </div>
          </div>
          <div className='procesos-container'>
            <div className='procesos-img-container'>
              <img src={procesos} alt="circulo color negro" className='procesos-img img-fluid' />
            </div>
            <div className='titulo-smart-container'>
              <h1 className='titulo-smart'>
                smart<span className='dot'>.</span>
              </h1>
              <div className='texto-container'>
                <h6 className='texto-smart'>
                  Nuestras soluciones de IoT ayudan a mejorar los procesos.
                  <br/>
                  <br/>
                  Al contar con una mayor cantidad de datos, permite una toma de decisiones más segura, reducción de pérdidas y gestionar de mejor manera los recursos para el cumplimiento de las metas.
                </h6>
              </div>
              <div className='btn-contacto-container'>
                <ButtonContacto />
              </div>
            </div>
          </div>
        </section>
      </>
    )
};

export default SectionTwo;