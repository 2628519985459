import './suscribete.css'
import iot from '../../assets/img/iotLogo.svg'
import FormSuscribete from '../formSuscribete/FormSuscribete';
import { Link } from 'react-router-dom';
import HubspotSubscribeFormMobile from '../HubspotSubscribeForm/HubspotSubscribeFormMobile';

function Suscribete() {

    return (
        <div className='Suscribete'>
            <Link className="iot-logo-footer" to="/"><img src={iot} alt="iot logo" /></Link>
            <div className='suscribete-btn-container-desktop d-none d-md-block mx-3'>
                {/* <input type='mail' placeholder='Ingresa tu correo' className='input-suscribete'></input>
                <button type='submit' className='btn-suscribete'>Suscribete</button> */}
                <FormSuscribete />
            </div>
            <div className='suscribete-btn-container-mobile d-block d-sm-block d-md-none'>
                <HubspotSubscribeFormMobile
                    region="na1"
                    portalId="21901438"
                    formId='cd3c1945-6e5a-4de4-8414-943600ec45f5'
                />
            </div>
        </div>
    )
};

export default Suscribete;