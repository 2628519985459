import './buttonContacto.css'
import iotArrow from '../../assets/img/iotArrow.svg'
import { Link } from 'react-router-dom';

function ButtonContacto() {
    return(
        <div className='contacto-container'>
            <Link className='contacto-font-mobile mx-auto' to="/form-contacto">contacto</Link>
            <img src={iotArrow} alt="flecha contacto" className='img-contacto' />
        </div>
    )
};

export default ButtonContacto;