import './title.css'

function Title({titulo, subtitulo, seccion}) {

    if(seccion===1) {
        return (
            <div className='Title'>
                <h1 className='title'>
                    {titulo}
                </h1>
                <h2 className='subtitle'>
                    {subtitulo}
                </h2>
            </div>
        );
    }

    if(seccion===3) {
        return (
            <div className='Title-3'>
                <h1 className='title-3'>
                    {titulo}
                </h1>
                <h2 className='subtitle-3'>
                    {subtitulo}
                </h2>
            </div>
        );
    }
}

export default Title;