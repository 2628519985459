import './form.css'
import HubspotContactForm from '../../components/HubspotContactForm/HubspotContactForm';
import { Link } from 'react-router-dom';
import flechaAzul from '../../assets/img/flechaAzul.svg'
import iot from '../../assets/img/iotLogo.svg'

function formContacto() {
  return (
    <main>
      <div className='form-header mt-5 d-md-none'>
        <Link className="d-flex align-items-center justify-content-center logo-container" to="/">
          <img alt="Logo" src={iot} />
        </Link>
        <p className="svc-text-font mt-4 d-none d-lg-block">
            ¿Necesitas contactar con 
                <br/>
            uno de nuestros agentes?
                <br/>
            Escríbenos a través del
                <br/>
            siguiente formulario
        </p>
        <p className="svc-text-font mt-4 d-lg-none">
            ¿Necesitas contactar con 
                <br/>
            uno de nuestros agentes?
                <br/>
            Escríbenos a través del
                <br/>
            siguiente formulario
        </p>
      </div>
      <div className="form-container">
      <div className='form-header-desktop text-center'>
        <Link className="logo-container" to="/">
            <img alt="Logo" src={iot}/>
        </Link>
        <p className="svc-text-font mt-5 mb-5">
            ¿Necesitas contactar con 
                <br/>
            uno de nuestros agentes?
                <br/>
            Escríbenos a través del
                <br/>
            siguiente formulario
        </p>
      </div>
        <div className="container">
          <HubspotContactForm 
            region="na1"
            portalId="21901438"
            formId='4dfe1c35-b93a-4e08-90ec-d555df5ac3eb'
          />
        </div> 
      <div className="form-footer mt-2">
        Al enviar este formulario, estás aceptando los
          <br/> 
        <span className='tc-font'>términos y condiciones</span> de IOTLabs
      </div>
      <div className='text-center mt-5'>
        <Link className='link-element-orange' to="/"><img alt="flecha-volver" src={flechaAzul} className="me-2" />Volver al inicio</Link>
      </div>
      </div>
    </main> 
  );
}

export default formContacto;