import './home.css'
import Navbar from '../../components/navbar/Navbar';
import SectionOne from '../../components/sectionOne/SectionOne';
import SectionTwo from '../../components/sectionTwo/SectionTwo';
import SectionThree from '../../components/sectionThree/SectionThree';
import SectionFour from '../../components/sectionFour/SectionFour';
import SectionFive from '../../components/sectionFive/SectionFive';
import Suscribete from '../../components/suscribete/Suscribete';

function Home() {

  return (
  <div className='Home'>
      <Navbar />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <Suscribete />
  </div>
  );
}

export default Home;