import './card.css'
import iotArrowRight from '../../assets/img/iotArrowRight.svg'

function Card({title, img, text}) {
    return(
        <div className='soluciones-card'>
            <div className='soluciones-card-content'>
                <div className='soluciones-card-header'>
                    <div className='soluciones-img'>
                        <img src={img} alt="icono referencial" width={34} height={34}/>
                    </div>
                    <div className='header-title'>
                        <h2 className='card-title'>{title}</h2>
                    </div>
                </div>
                <div className='soluciones-body-header'>
                    <div className='soluciones-body-text'>
                        {text}
                    </div>
                </div>
                <div className='soluciones-footer-header'>
                    <div className='soluciones-arrow-container'>
                        <img src={iotArrowRight} alt="flecha en direccion hacia la derecha" className='img-fluid arrow-soluciones'/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Card;