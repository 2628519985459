import './formSuscribete.css'
import HubspotSubscribeForm from '../HubspotSubscribeForm/HubspotSubscribeForm';

function formSuscribete() {
  return (
    <main id='form-suscribete'>
      <div className="form-container-suscribete">
        <HubspotSubscribeForm
          region="na1"
          portalId="21901438"
          formId='cd3c1945-6e5a-4de4-8414-943600ec45f5'
        />
      </div> 
    </main>
  );
}

export default formSuscribete;