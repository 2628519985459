import './sectionFour.css'
import Title from '../title/Title';
import ButtonContacto from '../buttonContacto/ButtonContacto';
import imgIndustrias from '../../assets/img/imgIndustrias.png'

function SectionFour() {

    return (
        <section className='section-4' id='industrias'>
            <div className='main-container-4'>
                <Title 
                    titulo={["Todas las ",<b>industrias,</b>, <br/>, "todas las ",<b>soluciones</b>, <span className='dot'>.</span>]}
                    subtitulo={"Nuestros productos están presentes en distintos mercados, como el retail, consumo masivo de alimentos, lavanderías compartidas, la gran Minería, el sector eléctrico, servicios de Salud y seguridad"}
                    seccion={3}
                />
                <div className='button-container-4'>
                    <ButtonContacto />
                </div>
                <div className='img-container-4'>
                    <img src={imgIndustrias} alt="circulo color negro" className='img-fluid' />
                </div>
            </div>
        </section>
    )
};

export default SectionFour;