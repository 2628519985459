import './navbar.css'
import iot from '../../assets/img/iotLogo.svg'
import ButtonContacto from '../buttonContacto/ButtonContacto';
import ButtonContactoMobile from '../buttonContacto/ButtonContactoMobile';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

function Navbar() {

    return (
        <div className="Navbar">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <div className='toggler-container'>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <Link className="navbar-brand iot-logo" to="/"><img src={iot} alt="iot logo" /></Link>
                    <ButtonContactoMobile />
                    <div className="collapse navbar-collapse" id="navbarToggler">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 menu-container">
                            <li className="nav-item">
                                <HashLink to="/#soluciones" className='nav-link menu-element active'>soluciones</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink to="/#industrias" className='nav-link menu-element active'>industrias</HashLink>
                            </li>
                        </ul>
                    </div>
                    <div className='btn-container'>
                        <ButtonContacto />
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;