import './sectionFive.css'
import Card from '../card/Card';
import cardImg1 from '../../assets/img/cardImg1.svg'
import cardImg2 from '../../assets/img/cardImg2.svg'
import cardImg3 from '../../assets/img/cardImg3.svg'
import cardImg4 from '../../assets/img/cardImg4.svg'
import cardImg5 from '../../assets/img/cardImg5.svg'
import cardImg6 from '../../assets/img/cardImg6.svg'

function SectionFive() {

    return (
        <section className='section-5' id='soluciones'>
            <div className='main-container-5'>
                <h1 className='section-five-title'>Soluciones<span className='dot'>.</span></h1>
            </div>
            <div className='cards-container'>
                <div className='row'>
                    <div className='col card-col'>
                        <Card 
                            img={cardImg1}
                            title={"Telemedida"}
                            text={"Descubre el futuro de la medición eléctrica con nuestras soluciones. Ahorra tiempo, reduce costos y mejora la gestión. La fiabilidad en la conexión es el éxito de su operación."}
                        />
                    </div>
                    <div className='col card-col'>
                        <Card 
                            img={cardImg2}
                            title={"Telemetría"}
                            text={"Optimiza tu rendimiento, toma decisiones informadas y maximiza tus resultados. Datos precisos y en tiempo real para impulsar tu éxito. Transforma tus datos en acciones efectivas."}
                        />
                    </div>
                    <div className='col card-col'>
                        <Card 
                            img={cardImg4}
                            title={"Vending"}
                            text={"Dale un impulso a tu negocio con nuestro método de pago electrónicos y telemetría. Aumenta tus ventas, reduce el tiempo de transacción. Obtén datos precisos de stock y reposición."}
                        />
                    </div>
                    <div className='col card-col'>
                        <Card 
                            img={cardImg3}
                            title={"Lavandería"}
                            text={"Potencie su lavandería con nuestro innovador sistema de pago y conectividad. Mejore la eficiencia, atraiga más clientes y aumente sus ganancias."}
                        />
                    </div>
                    <div className='col card-col'>
                        <Card 
                            img={cardImg5}
                            title={"GPS y Tracking Indoor"}
                            text={"Versatilidad de equipos GPS con diferentes prestaciones. Además con el sistema de posicionamiento en interiores contará con el control y seguridad total de personas y activos."}
                        />
                    </div>
                    <div className='col card-col'>
                        <Card 
                            img={cardImg6}
                            title={"Desarrollos a medida"}
                            text={"Proyectos personalizados para satisfacer sus necesidades específicas y automatizar sus actividades claves. Liberará el potencial completo de su negocio gestionando su operación y agilizando los procesos"}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
};

export default SectionFive;